<template>
  <div></div>
</template>
<script>
// A separate Logout view is needed to implement the logout process
// as a navigation between pages. This way, the navigation guard
// of the Record view can block it (if there are unsaved changes)
export default {
  mounted() {
    this.$store.commit("setUser", null);
    this.$store.commit("setRecords", null);
    this.$router.replace({ name: "Login", query: this.$route.query });
  }
};
</script>
